import React from 'react'

import { Link } from "react-router-dom";
import Microoverdraft from "../images/MICRO- CASH – CREDIT OVERDRAFT.jpg";
import Header from '../inc/Header';


function MicrocashLoan() {
    return (
        <div>
            <Header />

            <br />
            <br />
            <br />
            <div className='mahilaloanmurshidabad'>
                <div className="album py-1">
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">MICRO- CASH – CREDIT/OVERDRAFT</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div class="card mb-3" >
                                <div className="row g-0">
                                    <div className="col-md-5">
                                        <div className='hoverimage'>
                                            <img src={Microoverdraft} className="img-fluid rounded-start image1" alt="Microoverdraft"/>
                                            <div className="overlay">MICRO- CASH – CREDIT/OVERDRAFT <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg></div>
                                        </div>

                                    </div>
                                    <div className="col-md-7">
                                        <div className="card-body">
                                            <h4 style={{ color: "Darkblue" }} className="card-title mb-2"><b>MICRO- CASH – CREDIT/OVERDRAFT <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg></b></h4>
                                         
                                        
                    <p style={{color:"grey"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill " viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 1. Own Contribution / Owner Contribution  = 30%-40% </p>
                    <p style={{color:"grey"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 	2. Permissible Finance = 60%-70%   </p>
                    <p style={{color:"grey"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 3. Business- Proof : Trade license , Business License , Business Vintage  Proof – 3Yrs. </p>
                    <p style={{color:"grey"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 4.	Minimum Bank Balance 20,000, Business income proof  : ITR . </p>
                    <p style={{color:"grey"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 5.	REGULAR STOCK AUDIT , PDC / CREDIT MANDATE    </p>
                    <p style={{color:"grey"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 6.	Age of Owner / Beneficial owner – 25-58Yrs. </p>
                    <p style={{color:"grey"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> 7.	 Basic KYC of Owner .Aadhaar / Voter, PAN Card    </p>
                    <p style={{color:"grey"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>  8.	Renewal after every 1 ( one ) year.   </p>
                    
                   
                   

                
                   
                  

                                        </div>
                                    </div>
                                </div>
                                
                            </div>


                        </div>
                        <center>
                    <div className="d-flex justify-content-between align-items-center mb-0">

<a href="loan-apply" className="womenloanmurshidabad">Apply Loan</a>
<a href="tel: 9641435329" className="callbutton">Call Now</a>
</div>
                    </center>
                    </div>

                
                </div>
            </div>
            <br />
        </div>
    )
}

export default MicrocashLoan