import React from 'react'
import {Link} from "react-router-dom";
import AddCareer from '../AddCareer';
import Header from '../inc/Header';

function Careerpage() {
  return (
    <div>
        <Header/>
        <br/>
        <br/>
        <br/>
        <div className='mahilaloanmurshidabad'>
            <div className="album py-1">
                <div className="container mt-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Career</li>
                        </ol>
                    </nav>
                </div>
                </div>
            </div>
            <br/>

            
              <div className="album py-0">
                <div className="container">
                  <br/>
                  <h5 style={{color:'maroon'}}>Send your CV.  <a href='careermzmuccsl@gmail.com'>careermzmuccsl@gmail.com</a> </h5> 
                  <br/>
            <center>
<h3 className='mb-3' style={{color:"maroon"}}><b>Job Opportunity</b></h3>
            </center>
            </div>
            </div>
            <AddCareer/>
            <br/>
    </div>
  )
}

export default Careerpage