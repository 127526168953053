import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import { db } from '../../firebase'

import ScrollToTop from "react-scroll-to-top";
import ReactPlayer from "react-player";
import YoutubeV from './YoutubeV';
import Header from '../inc/Header';
// import Videogallery from "../pages/Videogallery";

function GalleryActivity(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(40);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [gallery, setGallery] = useState([])
    const navigate = useNavigate()

    const galleryCollectionRef = collection(db, "SOCIAL ACTIVITIES & GALLARY");
    useEffect(() => {

        const getGallery = async () => {
            const data = await getDocs(galleryCollectionRef);
            setGallery(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getGallery()
    }, [])
    return (

        <>
            <Header/>

            <br />
            <br />
            <br />
            <div className='mahilaloanmurshidabad'>
            <div className="album py-1">
                <div className="container mt-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Social Activities & Gallary</li>
                        </ol>
                    </nav>
                </div>
                </div>
            </div>
            <ScrollToTop smooth top="100" color="Darkblue" />




            <div className="album py-2">
                <div className="container">

                    <div className="row">

                        <div className="col-md-6">

                            <div className="card-body">
                                <input type="text" class="form-control" placeholder="Search" name="location"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />



                            </div>


                        </div>

                    </div>

                </div>
            </div>

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {gallery.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())





                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((MURSHIDABADZILAMAHILAURBAN) => {
                            return (

                                <div className="col-md-4 mt-4" >
                                    <div className="card mb-4 border-White box ">
                                        <img className="card-img-top" src={MURSHIDABADZILAMAHILAURBAN.img} alt={MURSHIDABADZILAMAHILAURBAN.title} />
                                        <div className="card-body">
                                            <center>
                                                <div className='ptextcolor'>
                                                <h6  className="card-text"> <b>{MURSHIDABADZILAMAHILAURBAN.title} </b></h6>
                                                </div>
                                            </center>
                                            <p className="card-text mb-2">{MURSHIDABADZILAMAHILAURBAN.dis}</p>



                                            <div className="d-flex justify-content-between align-items-center">






                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>

            <YoutubeV/>
        </>

    );
}

export default GalleryActivity;