





import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';

import DropdownButton from 'react-bootstrap/DropdownButton'
import './Header.css'
// import { Button,Dropdown} from 'react-bootstrap';
import { Button, Navbar, Dropdown, Container, Nav, Form, FormControl, NavDropdown } from 'react-bootstrap';
import { useUserAuth } from '../../contexts/UserAuthContext'

import Spinner from 'react-bootstrap/Spinner';



function Header(props) {

  let time = new Date().toLocaleTimeString();
  const [ctime, setCtime] = useState(time);

  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    setCtime(time);
  };

  setInterval(UpdateTime, 1000);



  const navigate = useNavigate()
  const { user, logOut } = useUserAuth()
  const [photoURL, setPhotoURL] = useState()

  //const emailVerified = user.emailVerified;
  const handleLogOut = async () => {
    try {
      await logOut()
      window.location.reload()
      navigate("/")
    } catch (err) {

    }
  }

  useEffect(() => {
    if (user?.photoURL) {
      setPhotoURL(user.photoURL)
    }

  }, [user])


  return (
    <>
      {/* <div className="mains container-fluid"> */}
      <div className="fixed-top">
      <div className="bestmahilaloanberhampore">
                    <div className="album py-1">

                        <div className="container">

                            <div className="row ">

                                <div className="col-md-5">
                                    <div className="d-flex justify-content-between align-items-center">

                                        <a style={{ color: "white" }} className="btn-gradnav" href="tel: +91 9641435329"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-minus" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                        </svg><small> +91 9641435329</small> </a>
                                    
                                    <a style={{ color: "white" }} href='mailto: msdmahilaucb@mzmuccsl.in'> <small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> msdmahilaucb@mzmuccsl.in</small></a>





                                    </div>

                                </div>

                                <div className="d-flex justify-content-between align-items-center">





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <Navbar bg="white shadow" expand="lg">
          <Container fluid>
            <Navbar.Brand>
              <Link to="/" className='me-0'>
              <div className='navcolor'>
                        <a className="navbar-brand" href="/">MZMUCCSL</a>
                        </div>
                {/* <img src={Logo} height="40px" width="75px" alt="" /> */}
              </Link>

            </Navbar.Brand>
            <div className="text-end d-grid gap-2 d-md-flex justify-content-md-end">

              {!user ? (

                <div>

                  <Link to="/Login">
                    <button type="button" className="buttonlogin me-4">LOGIN </button>
                  </Link>

                </div>
              ) :
                <>

                  <Dropdown className='dropdown'>
                    <Dropdown.Toggle variant="none" id="dropdown-basic">
                      <img className='userphoto' src={user.photoURL} alt=""/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                     
                        <Dropdown.Item>
                          <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='secondary btn-sm'><small>Dashboard</small></Button>
                        </Dropdown.Item>
                       
                        <Dropdown.Item>
                          <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='light btn-sm'>Upload  </Button>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Button variant='danger btn-sm' onClick={handleLogOut}><small>LOG OUT</small></Button>
                        </Dropdown.Item>
                      
                    </Dropdown.Menu>

                  </Dropdown>
                </>

              }

            </div>

            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '290px' }}
                navbarScroll
              >

                <li className="nav-item">
                  <Link to="/"  className="nav-link active" aria-current="page" href="#">Home</Link>
                </li>
                <li className="nav-item">
                  <Link  to="/profile" className="nav-link" href="#">Profile</Link>
                </li>


                <li className="nav-item">
                  <Link to="/deposit-scheme"  className="nav-link" >Deposite & Scheme</Link>
                </li>
                <li className="nav-item">
                  <Link to="/group"  className="nav-link" href="#">Group</Link>
                </li>
             
                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Loan & Services
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <div className='colordrop'>
                                        <li><a className="dropdown-item" href="/Vechile-Loan">VECHILE LOAN ( Three wheeler & Two Wheeler )</a></li>
                                        <li><a className="dropdown-item" href="/personal-salary-earner-loan">PERSONAL / SALARY EARNER LOAN</a></li>
                                        {/* <li><hr className="dropdown-divider" /></li> */}
                                        <li><a className="dropdown-item" href="/property-mortagage-loan">LOAN AGAINST PROPERTY( LAP) /MORTAGAGE LOAN</a></li>
                                        <li><a className="dropdown-item" href="/micro-cash-credit-overdraft-loan">MICRO- CASH – CREDIT/OVERDRAFT</a></li>
                                        <li><a className="dropdown-item" href="/Retail-Business-loan">RETAIL BUSINESS LOAN ( UP TO RS.50,000)</a></li>
                                        </div>
                                        
                                    </ul>
                                </li>
               
              
             
             
                <li className="nav-item">
                  <Link to="/career"  className="nav-link" href="#">Career</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact-us"  className="nav-link " href="#">Contact & Queries</Link>
                </li>


             














              </Nav>
              <a href='download'>
                                <button className="buttonhelpline" type="submit">Download <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
</svg></button>
</a>   
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

    </>

  );
}

export default Header;



