import React from 'react'
import { Link } from "react-router-dom";

import ScrollToTop from "react-scroll-to-top";
import Microcreditloan from "../images/micro-loan.jpg";
import grouploan from "../images/group loan new.jpg";
import Header from '../inc/Header';


function Group() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="Darkblue" />
            <div className='mahilaloanmurshidabad'>
                <div className="album py-1">
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Group</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="album py-3">
                <div className="container">
                    <center>
                        <div className='colorte'>
                            <h3>SHG LOAN -2%( Terms & Conditions Applicable )</h3>
                        </div>
                    </center>
                    <h5 className='mb-3'><b>Eligibility</b></h5>
                    <p className='mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                        <circle cx="8" cy="8" r="8" />
                    </svg> Women should form the group</p>
                    <p className='mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                        <circle cx="8" cy="8" r="8" />
                    </svg> 	Must be a member.</p>
                    <p className='mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                        <circle cx="8" cy="8" r="8" />
                    </svg> Membership minimum 10 and maximum 20.</p>
                    <p className='mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                        <circle cx="8" cy="8" r="8" />
                    </svg> Uninterrupted savings for 6 months or more by all the members.</p>
                    <p className='mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                        <circle cx="8" cy="8" r="8" />
                    </svg> Monthly savings of member of a group will be minimum of Rs. 50 and maximum Rs. 500. </p>
                    <br />
                    <div className='bengalitextcolor'>
                        <h6>মহিলাদের দল গঠন করতে হবে</h6>
                        <h6>সদস্য হতে হবে। গ্রুপের ক্ষেত্রে, গ্রুপের আকার = 10-20</h6>
                        <h6>সকল সদস্যের ৬ মাস বা ততোধিক সময়ের নিরবচ্ছিন্ন সঞ্চয়।</h6>
                        <h6>একটি গ্রুপের সদস্যের মাসিক সঞ্চয় সর্বনিম্ন ৫০ টাকা এবং সর্বোচ্চ ৫০০ টাকা হবে. </h6>
                    </div>
                </div>
            </div>


            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div class="card mb-3" >
                                <div className="row g-0">
                                    <div className="col-md-5">
                                        <div className='hoverimage'>
                                            <img src={Microcreditloan} className="img-fluid rounded-start image1" alt="Microcreditloan"/>
                                            <div className="overlay">MICRO -CREDIT /GROUP    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg></div>
                                        </div>

                                    </div>
                                    <div className="col-md-7">
                                        <div className="card-body">
                                            <h4 style={{ color: "Darkblue" }} className="card-title mb-2"><b>MICRO -CREDIT / GROUP    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg></b></h4>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 1.	MODE OF CREDIT : GROUP / Individual </p>

                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 2.	<b>Eligibility :</b> Women Candidates only , ALL the members are equally liable for repayment of Loan.   </p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 3.	<b>Purpose :</b> Any purpose other than personal needs/consumptions .Income Generation Loan. </p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 4.	Loan Eligibility as per SaDhan Standards.</p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 5. Income & Credit History Assessment –as per Check list </p>


                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 6. Credit& Risk assessment as per Check list,  Phase wise lending .  </p>
    <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 7.	In case of Group , Size of Group =5-8.   </p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 8. Payment Cycle : Weekly / Bi-Monthly / Monthly  </p>

                                        </div>
                                    </div> 
                                 
                                    
                                </div>
                                
                            </div>
<br/>
<div class="card mb-3" >
                                <div className="row g-0">
                                    <div className="col-md-5">
                                        <div className='hoverimage'>
                                            <img src={grouploan} className="img-fluid rounded-start image1" alt="grouploan"/>
                                            <div className="overlay">GROUP LOAN <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg></div>
                                        </div>

                                    </div>
                                    <div className="col-md-7">
                                        <div className="card-body">
                                            <h4 style={{ color: "Darkblue" }} className="card-title mb-2"><b>GROUP LOAN <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg></b></h4>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 1. ক্রেডিটের ধরণ: গ্রুপ / ব্যক্তি </p>

                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 2. যোগ্যতা: শুধুমাত্র মহিলা প্রার্থীরা, সমস্ত সদস্য ঋণ পরিশোধের জন্য সমানভাবে দায়বদ্ধ।  </p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 3. উদ্দেশ্যঃ ব্যক্তিগত চাহিদা/ভোগ ব্যতীত অন্য কোন উদ্দেশ্য। ইনকাম জেনারেশন লোন। (IGL ) </p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 4. সাধন(SADHAN )  মান অনুযায়ী ঋণ যোগ্যতা।</p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 5. আয় এবং ক্রেডিট ইতিহাস মূল্যায়ন - চেক তালিকা অনুযায়ী  </p>


                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 6. চেক লিস্ট অনুযায়ী ঋণ ও ঝুঁকি মূল্যায়ন, পর্যায়ক্রমে ঋণদান। ( Loan Policy )</p>
    <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 7. গ্রুপের ক্ষেত্রে, গ্রুপের আকার = 5-8।  </p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg> 8. ঋণের কিস্তি পরিশোধ: সাপ্তাহিক/দ্বি-মাসিক/মাসিক  </p>

                                        </div>
                                    </div>
                                 
                                    
                                </div>
                                
                            </div>

                        </div>
                 
                    </div>

                
                </div>
            </div>
        </div>
    )
}

export default Group